import React from "react";
import Layout from "../components/layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { SafeHtmlParser } from "../components/safeHtmlParser";

const ContactUsPage = ({ data }) => {
	const {
		pageData: {
			seoFields,
			contactPageField: { contactIntoSection, buttonLabel },
		},
	} = data;

	const { siteUrl } = data.site.siteMetadata;

	return (
		<Layout>
			<Helmet>
				<script
					src="https://www.google.com/recaptcha/api.js"
					async
					defer
				></script>
			</Helmet>
			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/contact-us`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.node.sourceUrl}`,
							width: `${seoFields?.image?.node.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.node.localFile.childImageSharp.original.height}`,
							alt: "Contact Holding Space",
						},
					],
				}}
			/>

			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<h1 className="text-primary">
								{contactIntoSection?.heading || "Contact Us"}
							</h1>
							<SafeHtmlParser htmlContent={contactIntoSection?.description} />
						</Col>
					</Row>
					<Row className="mt-5">
						<Col>
							<form action="https://formspree.io/f/mwkavazn" method="POST">
								<div class="form-group">
									<input
										class="form-control bg-light border-0 py-5"
										placeholder="Name"
										type="text"
										name="name"
									/>
								</div>
								<div class="form-group">
									<input
										class="form-control bg-light border-0 py-5"
										placeholder="Email Address"
										type="email"
										name="_replyto"
									/>
								</div>
								<div class="form-group">
									<input
										class="form-control bg-light border-0 py-5"
										placeholder="Telephone Number"
										type="text"
										name="telephone"
									/>
								</div>
								<div class="form-group">
									<textarea
										class="form-control bg-light border-0 py-5"
										rows="3"
										name="message"
										placeholder="Message"
									></textarea>
								</div>
								<div class="form-group">
									<textarea
										class="form-control bg-light border-0 py-5"
										rows="3"
										name="time available"
										placeholder="Time available for callback (Example: Monday at 3pm). Please be aware the team are not available evenings or weekends."
									></textarea>
								</div>

								<div
									class="g-recaptcha my-3"
									data-sitekey="6Ledw8caAAAAAJhWQ_uchVO4C5IFwpJcmFE6t6iT"
								></div>
								<button
									class="btn btn-outline-primary mt-3"
									id="contact-send-btn"
									type="submit"
								>
									{buttonLabel ?? "Send Message"}
								</button>
							</form>
						</Col>
					</Row>
				</Container>
			</section>
		</Layout>
	);
};

export const query = graphql`
	query {
		site {
			siteMetadata {
				siteUrl
			}
		}
		socialImage: file(relativePath: { eq: "hero-home-comp.jpg" }) {
			publicURL
		}
		pageData: wpPage(slug: { eq: "contact-us" }) {
			seoFields {
				title
				metaDescription
				opengraphTitle
				opengraphDescription
				localBusinessSchema
				image {
					node {
						sourceUrl
						altText
						localFile {
							childImageSharp {
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
								original {
									width
									height
								}
							}
						}
					}
				}
			}
			contactPageField {
				buttonLabel
				contactIntoSection {
					heading
					description
				}
			}
		}
	}
`;

export default ContactUsPage;
